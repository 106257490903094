import { Container } from '@mui/material'
import React from 'react'

const GoogleMap = () => {
  return (
    <Container className='h-full my-4'>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d334.3502447628904!2d36.82088002748186!3d-1.2747505113533872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f170026926249%3A0x69d31beeccd206ca!2sFinechek%20(A)%20System!5e1!3m2!1sen!2ske!4v1728896883066!5m2!1sen!2ske" 
            height="500"
            width="1200"
            className='w-full'
            loading="lazy"
            title="Finechek (A) System Location">
        </iframe>
    </Container>
  )
}

export default GoogleMap


