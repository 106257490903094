import React, { useRef } from "react";
import styled from "styled-components";
// Import ToastContainer and toast
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

// Assets
import ContactImg3 from "../../assets/img/contact 1.png";
import GoogleMap from '../../components/map/googlemap.js';

export default function Contact() {
  const form = useRef(); // Keeps a reference to the form

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent the form from submitting normally (refreshing page)
    console.log('Send email function is triggered');
    
    // Using FormData to extract data from form fields
    const formData = new FormData(form.current); 
    const data = Object.fromEntries(formData); // Converts FormData to a regular JS object

    console.log(data);
    try {
      // Send a POST request to the backend
      const response = await fetch('https://chem-labs.com/api/contact/finechek', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Ensure the request is JSON
        },
        body: JSON.stringify(data), // Convert the data object to JSON
      });

      // Handle the response
      if (response.ok) {
        console.log('Message Sent Successfully!');
        toast.success('Message sent successfully!'); // Show success toast
        
        // Reset the form fields
        form.current.reset(); // Reset the form fields
      } else {
        console.log('Failed to Send Message');
        toast.error('Failed to send message.'); // Show error toast
        form.current.reset(); // Reset the form fields
      }
    } catch (error) {
      console.log('Error:', error);
      toast.error('An error occurred while sending the message.'); // Show error toast
      form.current.reset(); // Reset the form fields
    }
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">We're Just a Message Away!</h1>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form ref={form} onSubmit={sendEmail}> 
                <label className="font19">Full Name:</label>
                <input type="text" name="from_name" required /> 
                
                <label className="font13">Email:</label>  
                <input type="email" name="reply_to" required /> 
                
                <label className="font13">Phone Number:</label>
                <input type="text" name="phone_number" required /> 
                
                <label className="font13">Subject:</label>
                <input type="text" name="subject" required />
                
                <label className="font13">Message:</label>
                <textarea rows="4" name="message" required />

                <SumbitWrapper className="flex">
                  <ButtonInput 
                    type="submit" 
                    value="Send Message" 
                    className="pointer animate radius8" 
                    style={{ maxWidth: "220px" }} 
                  />
                </SumbitWrapper>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap />
      {/* Add ToastContainer to render the toast messages */}
      <ToastContainer 
        position="top-right" // Position of the toast messages
        autoClose={5000} // Duration for which the toast should be visible
        hideProgressBar={false} // Show progress bar
        newestOnTop={false} // Newest toast appears at the bottom
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }

  input:focus,
  textarea:focus {
    outline: none; /* Remove the default focus outline */
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset; /* Replace 'white' with your background color */
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: black; /* Replace 'black' with your desired text color */
    transition: background-color 5000s ease-in-out 0s; /* Keep the autofill color for a long time */
  }
`;

const ButtonInput = styled.input`
  border: 1px solid rgb(119, 31, 115);
  background-color: rgb(119, 31, 115) !important;
  width: 100%;
  padding: 24px;
  outline: none;
  color: #fff;
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
